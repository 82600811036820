import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataSubjectConstants } from '../constant/data-subject.const';
import { BeAgentResponse } from '../interface/be-agent';
import { ResponseApi } from '../interface/response';
import { DataSubjectService } from '../service/data-subject.service';
import { HttpService } from '../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class PageResolverResolver implements Resolve<boolean> {

  constructor(
    private httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    let params = `url=${this.document.baseURI}`;
    if (route.queryParams.identifier) {
      params += `&identifier=${route.queryParams.identifier}`;
    }

    return this.httpService.get<ResponseApi<BeAgentResponse>>('institution/configuration/be-agent', params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SuccessResolver implements Resolve<boolean> {

  constructor(private dataSubject: DataSubjectService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.dataSubject.register(DataSubjectConstants.REGISTER_SUCCESS_TEXT);
    return true;
  }
}


<div class="container" [style.background-color]="data?.splashBackgroundColor">

    <div class="header">
        <img class="logo" [src]="data?.splashLogo" alt="">
        <div *ngIf="!mobileDevice">
          <div class="creating">
            {{ data.splashTitle }}
          </div>
          <div class="watch-video">
            {{ data.splashSubtitle }}
          </div>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="video-information" #demoYouTubePlayer>
            <youtube-player
              *ngIf="videoId"
              [width]="videoWidth"
              [height]="videoHeight"
              [videoId]="videoId"
              suggestedQuality="medium"
              (ready)="onPlayerReady($event)"
              (stateChange)="onPlayerStateChange($event)"
              (error)="onError($event)"
              [playerVars]="playerConfig">
            </youtube-player>
        </div>
    </div>
    <div class="actions">
        <button *ngIf="showJumpButton" class="jump-video" mat-stroked-button (click)="goAuthentication()">
            {{ data?.splashTxtButton }}
        </button>
    </div>

</div>

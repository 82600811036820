import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataSubjectConstants } from 'src/app/constant/data-subject.const';
import { DataSubjectService } from 'src/app/service/data-subject.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit {

  languageList: any;
  mainImageText: string;
  agencyLogo: string;
  @ViewChild('backgroundImage') backgroundImage: ElementRef;
  @ViewChild('backgroundText') backgroundText: ElementRef;

  constructor(
    private dataSubject: DataSubjectService
  ) {
  }

  ngOnInit(): void {
    this.dataSubject.register(DataSubjectConstants.BACKGROUND).subscribe((result: string) => {
      if (this.backgroundImage)
        this.backgroundImage.nativeElement.style.backgroundImage = `url(${result})`;
    });

    this.dataSubject.register(DataSubjectConstants.LOGO).subscribe((result: string) => {
      this.agencyLogo = result;
    });

    this.dataSubject.register(DataSubjectConstants.IMAGE_TEXT).subscribe(result => {
      this.mainImageText = result.MainImageText;
    });

    this.dataSubject.register(DataSubjectConstants.BACKGROUND_IMAGE_POSITION).subscribe((result: string) => {
      if (this.backgroundImage) {
        (this.backgroundImage.nativeElement as HTMLElement).style.backgroundPosition = result;
      }
    });

    this.dataSubject.register(DataSubjectConstants.BACKGROUND_TEXT_POSITION).subscribe((result: string) => {
      if (this.backgroundText) {
        let margin = '';

        switch (result) {
          case 'top-left':
          case 'left-top':
            margin = 'margin-bottom: auto; margin-right: auto';
            break;

          case 'top-center':
          case 'center-top':
            margin = 'margin-left: auto; margin-right: auto; margin-bottom: auto;';
            break;

          case 'top-right':
          case 'right-top':
            margin = 'margin-bottom: auto; margin-left: auto';
            break;

          case 'center-left':
          case 'left-center':
            margin = 'margin-right: auto; margin-bottom: auto; margin-top: auto;'
            break;

          case 'center-center':
          case 'center':
            margin = 'margin: auto';
            break;

          case 'center-right':
          case 'right-center':
            margin = 'margin-left: auto; margin-bottom: auto; margin-top: auto;'
            break;

          case 'bottom-left':
          case 'left-bottom':
            margin = 'margin-top: auto; margin-right: auto';
            break;

          case 'bottom-center':
          case 'center-bottom':
            margin = 'margin-left: auto; margin-right: auto; margin-top: auto;';
            break;

          case 'bottom-right':
          case 'right-bottom':
            margin = 'margin-top: auto; margin-left: auto';
            break;
        }

        (this.backgroundText.nativeElement as HTMLElement).setAttribute('style', margin);
      }
    });
  }
}


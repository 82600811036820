import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticVariableService {

  private _VAR: any;

  constructor() {
    this._VAR = {};
  }

  setVar(property: any, value: any) {
    Object.assign(this._VAR, { [property]: value });
  }

  getVar(property: any): any {
    return this._VAR[property];
  }
}

import { SplashParameters } from './../../interface/splash-parameters';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DocumentType } from 'src/app/enumerator/document.enum';
import { BeAgentResponse } from 'src/app/interface/be-agent';
import { Configuration } from 'src/app/interface/configuration';
import { Country as Country } from 'src/app/interface/country';
import { DefaultConfiguration } from 'src/app/interface/default-configuration';
import { Language } from 'src/app/interface/language';
import { PageConfiguration } from 'src/app/interface/page-configuration';
import { ResponseApi } from 'src/app/interface/response';
import { Translate } from 'src/app/interface/translate';
import { DataSubjectService } from 'src/app/service/data-subject.service';
import { DateFormatterService } from 'src/app/service/date-formatter.service';
import { HttpService } from 'src/app/service/http.service';
import { CustomValidator } from 'src/app/validators/custom';
import dayjs from 'dayjs';
import { DataSubjectConstants } from 'src/app/constant/data-subject.const';
import { Dayjs } from 'dayjs';
import { StaticVariableService } from 'src/app/service/static-variable.service';
import { TermsInfo } from 'src/app/interface/terms-info';
import { AgentRegisterRequest } from 'src/app/interface/agent-register';
import { Terms, TermsOfUse } from 'src/app/interface/terms-of-use';
import { ConfigConstants } from '../../constant/config.const';
import { SplashScreenComponent } from '../splash-screen/splash-screen.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from '../../../environments/environment';
declare var grecaptcha: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewInit {
  countryList: Country[];
  statesList: any[];
  cityList: any[];
  documentList = DocumentType;
  documentPlaceHolder: string;
  partnerForm: FormGroup;
  phoneCode: string;
  termsError: boolean;
  phoneNumberMask: string;
  pageContentConfiguration: PageConfiguration[];
  documentMask: string;
  termsAndConditions: string;
  dateTimeFormat: string;
  dateTimePlaceHolder: string;
  languageList: Language[];
  terms: Terms;
  embedded: boolean;
  availableLanguages: any;
  selectedLanguage: any;
  configDefaultByAgency: PageConfiguration;
  languageConfig: Translate;
  langTextJson: any;
  formConfiguration: any;
  countryListFiltred: Observable<Country[]>;
  statesListFiltred: Observable<any[]>;
  cityListFiltred: Observable<any[]>;
  filteredOptions: Observable<string[]>;

  @ViewChild('backgroundImage', { static: true }) backgroundImage: ElementRef;
  @ViewChild('partnerFormEl') partnerFormElement: any;
  tags: Array<any>;
  countryPhoneCode: any;
  accptedTermsTime: Dayjs;
  termsDescription: string;
  optionalDocument: ValidatorFn[];

  token: string | undefined;
  hasCaptchaToken: boolean = false;
  hasCaptchaError: boolean = false;
  agencyLogo: string;
  mobileDevice: boolean;

  @ViewChild('templateErrorInfo') templateErrorInfo: TemplateRef<any>;
  totalErrosForms = 0;
  totalErrosMessage = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpService: HttpService,
    private titleService: Title,
    private actRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private router: Router,
    private dataSubject: DataSubjectService,
    private staticVar: StaticVariableService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.embedded = window.location.pathname === '/embedded';
    this.languageConfig = new Translate();
    this.langTextJson = {};
    this.tags = [];
    this.optionalDocument = [Validators.required];

    this.documentPlaceHolder = '';
    this.configDefaultByAgency = {
      defaultConfig: {
        Logo: '',
        MainImage: '',
        FormButtonColor: '',
        Favicon: '',
        MainImagePosition: '',
        MainTextPosition: '',
        DocumentIsOptional: '',
        SuccessVideoLink: '',
        LinkToLearnMore: '',
        LinkHowToUsePlatform: '',
        SplashLogo: '',
        SplashLinkVideo: '',
        SplashDnsCheckout: '',
        SplashBackgroundColor: ''
      }
    }

    this.languageList = [
      { language: 'en-GB', name: 'English', flag: '../../../assets/images/united-kingdom.png', id: 1 },
      { language: 'pt-PT', name: 'Português (PT)', flag: '../../../assets/images/portugal.png', id: 2 },
      { language: 'es-ES', name: 'Españhol', flag: '../../../assets/images/spain.png', id: 3 },
      { language: 'pt-BR', name: 'Português (BR)', flag: '../../../assets/images/brazil.png', id: 4 },
      { language: 'it-IT', name: 'Italiano', flag: '../../../assets/images/italy.png', id: 5 },
      { language: 'de-DE', name: 'Deutsch', flag: '../../../assets/images/germany.png', id: 6 },
      { language: 'fr-FR', name: 'Français', flag: '../../../assets/images/france.png', id: 7 },
      { language: 'es-MX', name: 'Españhol (México)', flag: '../../../assets/images/mexico.png', id: 8 },
      { language: 'en-US', name: 'English (US)', flag: '../../../assets/images/united-states.png', id: 9 },
    ];

    this.actRoute.queryParams.subscribe(queryParams => Object.keys(queryParams).map(key => {
      this.tags.push({
        name: key,
        value: queryParams[key]
      })
    }));

    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      this.mobileDevice = result.matches;
      if (!result.matches) {
        if (this.backgroundImage) {
          this.backgroundImage.nativeElement.style.backgroundImage = `unset`;
          this.agencyLogo = '';
        }
      } else {
        if (this.backgroundImage) {
          this.backgroundImage.nativeElement.style.backgroundImage = `url(${this.configDefaultByAgency?.defaultConfig?.MainImage})`;
          this.agencyLogo = this.configDefaultByAgency.defaultConfig.Logo;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderToken();
    }, 500);
  }

  private async renderToken() {
    grecaptcha.enterprise.ready(async () => {
      grecaptcha.enterprise.execute(environment.recaptcha.siteKey, { action: 'BECOME_PARTNER' }).then((token: any) => {
        if (token) {
          this.hasCaptchaToken = true;
          this.token = token
        }
      });
    });
  }

  ngOnInit(): void {
    this.actRoute.data.subscribe((result: any) => {
      this.setPageContentConfiguration(result.data.data);
    });

    this.httpService.get<ResponseApi<Country[]>>('stateOrProvince')
      .subscribe((result: any) => {
        this.setStates(result.data);
      });

    this.selectedLanguage = [];
    this.createForm();

    setTimeout(() => {
      if ((this.countryList.length === 1) || this.embedded) {
        this.countryChanged({ value: this.countryList[0] });
      }
    }, 100);

    if (this.mobileDevice) {
      this.dataSubject.register(DataSubjectConstants.BACKGROUND).subscribe((result: string) => {
        if (this.backgroundImage) {
          this.backgroundImage.nativeElement.style.backgroundImage = `url(${result})`;
        }
      });

      this.dataSubject.register(DataSubjectConstants.LOGO).subscribe((result: string) => {
        this.agencyLogo = result;
      });
    }
  }

  compareCountry(firstCountry: Country, secondCountry: Country) {
    const countryWithPriority = [
      { name: 'Brasil', priority: -1 },
      { name: 'Canadá', priority: -1 },
      { name: 'Espanha', priority: -1 },
      { name: 'Estados Unidos', priority: -1 },
      { name: 'Itália', priority: -1 },
      { name: 'México', priority: -1 },
      { name: 'Portugal', priority: -1 }
    ]
    var getCountryPrioprityFirst = countryWithPriority.find(c => (c.name.toLowerCase() === firstCountry.name.toLowerCase()));
    var getCountryPriopritySecond = countryWithPriority.find(c => (c.name === secondCountry.name));

    if (getCountryPrioprityFirst && getCountryPriopritySecond) {
      return 0;
    }
    else if (getCountryPrioprityFirst) {
      return getCountryPrioprityFirst.priority;
    }

    return 0;
  }

  becomePartner() {
    const formValues = this.partnerForm.getRawValue();

    if (this.partnerForm.invalid) {
      const country = this.countryList.find(country => country.id === formValues?.country?.id);
      if (country && this.partnerForm.controls.termsOfUse.invalid) {
        this.countryChanged({ value: country }, true);
      }

      setTimeout(() => {
        this.scroll();
      }, 500);
      return;
    }

    const userIP = this.staticVar.getVar('ip');
    const termsInfo: TermsInfo[] = [];
    ['PrivacyPolicy', 'TermsAndConditions', 'PartnerContract'].forEach(element => {
      termsInfo.push({
        ip: userIP,
        name: element,
        value: this.formConfiguration[element].value,
        dateTime: this.accptedTermsTime.toISOString(),
        dateTimeStr: this.accptedTermsTime.format('YYYY-MM-DDTHH:mm:ss [GMT]Z')
      });
    });

    this.formConfiguration['PrivacyPolicy']
    this.formConfiguration['TermsAndConditions']
    this.formConfiguration['PartnerContract']
    const request: AgentRegisterRequest = {
      agencyName: formValues.agencyName,
      name: formValues.agentName,
      documentNumber: formValues.documentNumber,
      documentTypeId: Number((this.documentList as any)[formValues.documentType]),
      email: formValues.email,
      birthDate: DateFormatterService.formatDate(formValues.birthDate),
      mobilePhoneCountryCode: '+55',
      mobilePhone: formValues.phoneNumber,
      acceptedTermsOfUse: formValues.termsOfUse,
      countryId: formValues.country.id,
      tags: this.tags,
      termsInfo: termsInfo,
      captcha: this.token,
      stateOrProvinceId: formValues.state.id,
      cityId: formValues.city.id,
    }

    this.httpService.post('agent', request, this.selectedLanguage.id)
      .subscribe(
        response => {
          const successRedirectUrl = this.getConfigurationByLanguage(ConfigConstants.SUCCESS_REDIRECT_URL, this.selectedLanguage.language);
          this.renderToken();
          if (successRedirectUrl && successRedirectUrl.value && this.valideUrl(successRedirectUrl.value)) {
            window.location.href = successRedirectUrl.value;
          } else {
            this.openDialog(response.data);
            this.partnerForm.reset();
            this.partnerFormElement.resetForm();
            this.createForm();
          }
        },
        error => { this.openSnackBarError(error); this.renderToken(); })

    return;
  }

  countryChanged(ev: any, onlyTerms = false) {
    const countrySelected = ev.value;
    const country = this.countryList.find(country => country.name === countrySelected?.name);

    this.httpService.get<ResponseApi<TermsOfUse[]>>(`institution/terms?countryId=${country?.id}`)
      .subscribe((result) => {
        const terms = result.data.filter((term: TermsOfUse) => {
          return term.name !== 'PartnerContractPJ';
        });

        this.terms = terms.reduce((value: any, currentValue: any) => ({ ...value, [currentValue.name]: currentValue.url }), {});
        this.loadTextJson(this.selectedLanguage.language);
      });

    if (onlyTerms) {
      return;
    }

    this.formConfiguration = this.getCountryConfiguration(country!);
    this.documentChanged(this.formConfiguration.DocumentType.value);
    this.phoneNumberMask = this.formConfiguration.MobilePhoneFormat.value;
    this.dateTimeFormat = (this.formConfiguration.DateTimeFormat.value as string).replace(/[a-zA-Z]/g, '0');
    this.dateTimePlaceHolder = (this.formConfiguration.DateTimeFormat.value as string).toUpperCase();
    this.partnerForm.controls.birthDate.clearValidators();
    this.partnerForm.controls.birthDate.setValidators([Validators.pattern(this.formConfiguration.DateTimeRegexPattern.value), this.minimumAgeValidator(), Validators.required]);
    this.partnerForm.controls.birthDate.reset();
    this.partnerForm.controls.country.setValue(countrySelected);
    this.partnerForm.controls.documentNumber.enable();
    this.partnerForm.controls.birthDate.enable();
    this.partnerForm.controls.phoneNumber.enable();

    this.partnerForm.controls.state.enable();

    this.checkErrosNotification();
  }

  documentChanged(document: any) {
    this.partnerForm.controls.documentType.setValue(document);

    switch (document) {
      case 'CPF':
        this.documentMask = '000.000.000-00';
        this.partnerForm.controls.documentNumber.setValidators([...this.optionalDocument, CustomValidator.ValidateCpf]);
        this.documentPlaceHolder = document;
        break;

      case 'NIF':
        this.documentMask = '000 000 000';
        this.partnerForm.controls.documentNumber.setValidators([...this.optionalDocument, CustomValidator.ValidateNIF]);
        this.documentPlaceHolder = document;
        break;

      case 'RFC':
        this.documentMask = 'AAAAAAAAAAAAA';
        this.partnerForm.controls.documentNumber.setValidators([...this.optionalDocument]);
        this.documentPlaceHolder = document;
        break;
    }
    this.partnerForm.controls.documentNumber.reset();
  }

  languageChanged(event: any) {
    this.selectedLanguage = event.value;
    const textContent = this.propertyObject<Translate>(new Translate(), this.selectedLanguage.language);
    this.document.documentElement.lang = this.selectedLanguage.language; // Usado para set o atributo lang no index.html
    this.titleService.setTitle(textContent.PageTitle);
    this.languageConfig = textContent;
    this.dataSubject.sendValue(DataSubjectConstants.IMAGE_TEXT, textContent);

    const optionalDocument = this.configDefaultByAgency[this.selectedLanguage.language].find((config: PageConfiguration) => config.name === 'DocumentIsOptional')?.value;

    if (optionalDocument) {
      this.partnerForm.controls.documentNumber.setValidators([Validators.required]);
    } else {
      this.partnerForm.controls.documentNumber.setValidators([]);
    }

    this.partnerForm.controls.documentNumber.updateValueAndValidity({ emitEvent: true });
    this.loadTextJson(this.selectedLanguage.language);
  }

  stateChanged(event: MatAutocompleteSelectedEvent | any) {

    const stateId = event?.value?.id;
    this.httpService.get<ResponseApi<Country[]>>(`city/by-state-or-province/${stateId}`)
      .subscribe((result: any) => {
        this.setCity(result.data);
        this.partnerForm.controls.city.enable();
      });

    this.checkErrosNotification();
  }

  cityChanged(event: MatAutocompleteSelectedEvent | any) {
    this.checkErrosNotification();
  }

  setPageContentConfiguration(data: BeAgentResponse) {
    this.httpService = new HttpService(this.httpClient, data.institutionId);
    this.countryList = data.countries.map((country: any) => country);
    this.pageContentConfiguration = data.configurations;
    const languages = data.languages;

    this.setAvailableLanguages(data.languages);

    this.configDefaultByAgency = Object.keys(languages).reduce((obj, language) => (
      {
        ...obj,
        defaultConfig: this.propertyObject<DefaultConfiguration>(new DefaultConfiguration()),
        [language]: this.pageContentConfiguration.filter(pageConfig => language === pageConfig.language)
      }), {});

    this.dataSubject.sendValue(DataSubjectConstants.BACKGROUND, this.configDefaultByAgency.defaultConfig.MainImage);
    this.dataSubject.sendValue(DataSubjectConstants.LOGO, this.configDefaultByAgency.defaultConfig.Logo);
    this.dataSubject.sendValue(DataSubjectConstants.BACKGROUND_IMAGE_POSITION, this.configDefaultByAgency.defaultConfig.MainImagePosition ?? '');
    this.dataSubject.sendValue(DataSubjectConstants.BACKGROUND_TEXT_POSITION, this.configDefaultByAgency.defaultConfig.MainTextPosition ?? '');
    this.setFavIcon();

    setTimeout(() => {
      const browserLanguage = this.availableLanguages.findIndex((language: any) => language.language.includes(navigator.language));
      if (browserLanguage === -1) {
        const defaultLanguage = this.languageList.findIndex((lang: any) => lang.language === this.availableLanguages[0].language);
        this.languageChanged({ value: this.languageList[defaultLanguage] });
      }
      else {
        this.languageChanged({ value: this.availableLanguages[browserLanguage] });
      }
    });
  }

  setStates(data: Country[]) {
    this.statesList = data;

    this.statesListFiltred = this.partnerForm.controls.state.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : ''),
      map(value => this.filterStates(value)));
  }

  setCity(data: Country[]) {
    this.cityList = data;

    this.cityListFiltred = this.partnerForm.controls.city.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : ''),
      map(value => this.filterCity(value)));
  }

  setAvailableLanguages(languages: any) {
    const languagesId = Object.values(languages);
    this.availableLanguages = this.languageList.filter(lang => languagesId.includes(lang.id))
  }

  setBackgroundImage() {
    this.dataSubject.sendValue(DataSubjectConstants.BACKGROUND, this.configDefaultByAgency.defaultConfig.MainImage);
  }

  setFavIcon() {
    this.document.getElementById('appFavicon')?.setAttribute('href', this.configDefaultByAgency.defaultConfig.Favicon);
  }

  createForm() {
    this.optionalDocument = this.configDefaultByAgency.defaultConfig.DocumentIsOptional === 'true' ? [] : [Validators.required];

    this.partnerForm = new FormGroup({
      agentName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]),
      country: new FormControl(null, [Validators.required]),
      agencyName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      termsOfUse: new FormControl(false, [Validators.requiredTrue]),
      documentType: new FormControl({ value: '', disabled: true }, [Validators.required]),
      documentNumber: new FormControl({ value: '', disabled: true }, this.optionalDocument),
      birthDate: new FormControl({ value: '', disabled: true }, [Validators.required, this.minimumAgeValidator()]),
      phoneNumber: new FormControl({ value: '', disabled: true }, [Validators.required]),
      state: new FormControl({ value: '', disabled: true }, [Validators.required]),
      city: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });
  }

  loadTextJson(language: string) {
    from(import(`../../../assets/i18n/${language}.json`)).subscribe(res => this.langTextJson = res);
    setTimeout(() => {
      this.renderTermsDescription();
    }, 150);
  }

  renderTermsDescription() {
    if (!this.langTextJson?.cardForm) return;

    const partnerContract = this.langTextJson?.cardForm?.politics.partnerContract;
    const termsOfUse = this.langTextJson?.cardForm?.politics.termsOfUse;
    const privacyPolicy = this.langTextJson?.cardForm?.politics.privacyPolicy;

    let terms = (this.langTextJson?.cardForm?.politics.description as string);
    this.termsDescription = terms
      .replace('{PartnerContract}', ` <b><a href="${this.terms.PartnerContract}" target="_blank">${partnerContract}</a></b>`)
      .replace('{TermsOfUse}', `<b><a href="${this.terms.TermsAndConditionsPdf}" target="_blank">${termsOfUse}</a></b>`)
      .replace('{PrivacyPolicy}', ` <b><a href="${this.terms.PrivacyPolicy}" target="_blank">${privacyPolicy}</a></b>`)
  }

  registerAcceptTime(event: MatCheckboxChange) {
    event.checked ? this.termsError = false : this.termsError = true;
    const dateTimezone = dayjs(new Date());
    this.accptedTermsTime = dateTimezone;
    this.checkErrosNotification();
  }

  getCountryConfiguration(country: Country) {
    return country?.configurations
      .filter(config => [
        'DateTimeRegexPattern',
        'MobilePhoneFormat',
        'DefautLanguage',
        'DocumentType',
        'DateTimeFormat',
        'MinimunAgeRegisterAgent',
        'PrivacyPolicy',
        'TermsAndConditions',
        'PartnerContract',
      ].includes(config.name))
      .reduce((obj, item) => (
        {
          ...obj,
          [item.name]: item
        }), {});
  }

  getConfigurationByLanguage(name: string | string[], language: string): any {
    const config = this.configDefaultByAgency[language];
    return Array.isArray(name) ? config?.filter((c: any) => c.name) : config?.find((c: any) => c.name === name);
  }

  openSnackBarError(response: any) {
    let messageError = '';
    switch (response.error.Message) {
      case 'EMAIL':
        // messageError = this.langTextJson.failedMessages.email;
        this.partnerForm.controls.email.setErrors({ emailAlreadyInUse: true });
        break;
      case 'AGENCYNAME':
        messageError = this.langTextJson.failedMessages.agency;
        this.partnerForm.controls.agencyName.setErrors({ agencyNameAlreadyInUse: true });
        break;
      case 'DOCUMENTNUMBER':
        messageError = this.langTextJson.failedMessages.document;
        this.partnerForm.controls.documentNumber.setErrors({ documentNumberAlreadyInUse: true });
        break;
      case 'AGENCYNAME-NOT-ALLOWED':
        messageError = this.langTextJson.failedMessages.agencyNameNotAllowed;
        this.partnerForm.controls.agencyName.setErrors({ agencyNameNotAllowed: true });
        break;
      default:
        messageError = this.langTextJson.failedMessages.generic;
        break;
    }

    return messageError ? this.snackBar.open(messageError, 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000
    }) : null;
  }

  filterStates(value: string | Country): Country[] {
    let filterValue: string;

    filterValue = (value as string).toLocaleLowerCase().replace('+', '');

    if (typeof value === 'object' && value !== null)
      filterValue = (value as any).name.toLowerCase();

    return this.statesList?.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue) || String(option.id).includes(filterValue));
  }

  filterCity(value: string | Country): Country[] {
    let filterValue: string;

    filterValue = (value as string).toLocaleLowerCase().replace('+', '');

    if (typeof value === 'object' && value !== null)
      filterValue = (value as any).name.toLowerCase();

    return this.cityList?.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue) || String(option.id).includes(filterValue));
  }

  displayFn(country: Country): string {
    return country ? `+${String(country.phoneCode)}` : '';
  }

  minimumAgeError() {
    const minAge = this.formConfiguration.MinimunAgeRegisterAgent.value;
    return (this.langTextJson.failedMessages.minimumAgeRequired as string).replace('{age}', minAge);
  }

  valideUrl(url: string): boolean {
    var pattern = /^((http([s]?)):\/)\/(([a-zA-Z0-9])+\.){0,}[a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.([a-zA-Z]{2,})){1,2}$/;
    return pattern.test(url);
  }

  minimumAgeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;

      if (!value) return null;

      let dateFormatted: dayjs.Dayjs | Date;
      if (!dayjs(value).isValid()) {
        value = DateFormatterService.formatDate(value)!;
      }

      const minimumAge = Number(this.formConfiguration.MinimunAgeRegisterAgent.value);
      dateFormatted = dayjs(value, this.formConfiguration.DateTimeFormat.value);
      this.formConfiguration.MinimunAgeRegisterAgent.value;

      const dateInput = dayjs(dateFormatted);
      const todayDate = dayjs();
      const difference = todayDate.diff(dateInput, 'year');
      return difference >= minimumAge ? null : { minimumAge: true };
    };
  }

  propertyObject<T>(object: any, language = null): T {
    const result = Object.keys(object).reduce((obj, key) => {
      const configurationResult = this.pageContentConfiguration.filter(pageConfig => pageConfig.language === language ?? null);
      const value = configurationResult.find(pageConfig => pageConfig.name === key)?.value;
      return {
        ...obj,
        [key]: value
      };
    }, <T>{});

    return result;
  }

  learnMore(): void {
    window.open(this.configDefaultByAgency.defaultConfig?.LinkToLearnMore, '_blank');
  }

  compareCategoryObjects(object1: any, object2: any): boolean {
    return object1 && object2 && object1.id == object2.id;
  }

  openDialog(objAgent: any): void {
    const data: SplashParameters = {
      splashLogo: this.configDefaultByAgency.defaultConfig.SplashLogo,
      splashTitle: this.languageConfig.SplashTitle,
      splashSubtitle: this.languageConfig.SplashSubtitle,
      splashTxtButton: this.languageConfig.SplashTxtButton,
      splashDnsCheckout: this.configDefaultByAgency.defaultConfig.SplashDnsCheckout,
      splashLinkVideo: this.configDefaultByAgency.defaultConfig.SplashLinkVideo,
      splashBackgroundColor: this.configDefaultByAgency.defaultConfig.SplashBackgroundColor,
      creationHash: objAgent.creationHash
    };

    this.dialog.open(SplashScreenComponent, {
      data,
      panelClass: ['full-screen-modal']
    });
  }

  scroll(): void {
    const containerBodyFormFields = document.getElementsByClassName('form')[0];
    const elementWithError: HTMLElement = document.querySelector('form .mat-form-field .ng-invalid')! || document.querySelector('.terms .ng-invalid');
    let parentContainerPosition = containerBodyFormFields.getBoundingClientRect();
    let elementHasErrorPosition = elementWithError.getBoundingClientRect();

    const calcTopHeader = 50;
    let positionElementError = elementHasErrorPosition.top - parentContainerPosition.top - calcTopHeader;

    window.scroll({
      top: positionElementError,
      left: 0,
      behavior: 'smooth'
    });

    this.notificationErrorSetup();
    this.notificationSnakBar();
  }


  async notificationErrorSetup(): Promise<void> {
    let erros = 0;
    let gerenalForms = this.partnerForm as FormGroup;
    erros += this.getErrorForm(gerenalForms);
    this.totalErrosForms = erros;
    this.totalErrosMessage = (this.langTextJson.failedMessages.snackBarErrors as string).replace('{totalErrosForms}', this.totalErrosForms.toString());
  }

  getErrorForm(formGroup: any): number {
    let errorCount = 0;
    for (let controlKey in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlKey)) {
        if (formGroup.controls[controlKey].errors != null && formGroup.controls[controlKey].status == 'INVALID' && (formGroup.controls[controlKey] != formGroup.controls['termsOfUse'])) {
          errorCount += 1;
        } else {
          if (formGroup.controls.country.value && formGroup.controls[controlKey].errors != null && formGroup.controls[controlKey].status == 'INVALID') {
            errorCount += 1;
          }
        }
      }
    }
    return errorCount;
  }

  notificationSnakBar(): void {
    this.snackBar.openFromTemplate(this.templateErrorInfo, {
      duration: 0,
      panelClass: ['notification_error'],
      verticalPosition: 'top'
    });
  }

  async checkErrosNotification(): Promise<void> {
    await this.notificationErrorSetup();
    if (this.totalErrosForms < 1) {
      this.snackBar.dismiss();
    }
  }
}

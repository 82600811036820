<div class="container-image" #backgroundImage>

    <img class="logo" [src]="agencyLogo" alt="">

    <div class="text">
        <h2 class="subtitle" #backgroundText>
            <div [innerHTML]="mainImageText | sanitizer"></div>
        </h2>
    </div>
</div>

import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  hideLeftPanel: boolean;

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }
    
  ngAfterViewInit(): void {
    this.injectScript();
  }

  ngOnInit(): void {
    this.hideLeftPanel = window.location.pathname === '/embedded';
  }

  private injectScript() {
    let urlScriptRecaptcha = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptcha.siteKey}`;
    const scriptElementhead = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElementhead, 'src', urlScriptRecaptcha);
    this.renderer.appendChild(this.document.head, scriptElementhead);
  }

}
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HttpService } from './service/http.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DateFormatterService } from './service/date-formatter.service';
import { LoaderComponent } from './compontent/loader.component';
import { RequestInterceptor } from './core/interceptor/request-interceptor';
import { SanitizerPipe } from './pipe/sanitizer.pipe';
import { HomeComponent } from './pages/home/home.component';
import { ImageComponent } from './compontent/home-image/image.component';
import { DataSubjectService } from './service/data-subject.service';
import { CommonModule } from '@angular/common';
import { InitializeApp } from './service/initialize.service';
import { StaticVariableService } from './service/static-variable.service';
import { SuccessComponent } from './pages/success/success.component';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { GtagModule } from 'angular-gtag';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    dropSpecialCharacters: false,
    validation: false,
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    SanitizerPipe,
    HomeComponent,
    ImageComponent,
    SuccessComponent,
    SplashScreenComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    FormsModule,
    YouTubePlayerModule,
    GtagModule.forRoot({ trackingId: 'GTM-K4HNVHJ', trackPageviews: true })
  ],
  providers: [
    HttpService,
    DateFormatterService,
    DataSubjectService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeApp,
      multi: true,
      deps: [HttpClient, StaticVariableService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SplashParameters } from 'src/app/interface/splash-parameters';
@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('demoYouTubePlayer') demoYouTubePlayer: ElementRef<HTMLDivElement>;
  videoWidth: number;
  videoHeight: number;
  showJumpButton = false;
  backofficeDns: string;
  mobileDevice: boolean;
  videoId: string;
  playerConfig = {
    autoplay: 1,
    controls: 0,
    showinfo: 0,
    autohide: 1,
    events: {
      'onReady': this.onPlayerReady,
      'onStateChange': this.onPlayerStateChange
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SplashParameters,
    private mdDialogRef: MatDialogRef<SplashScreenComponent>,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.mdDialogRef.disableClose = true;
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      this.mobileDevice = result.matches;
    });
  }

  ngOnInit(): void {
    if (this.data && this.data.splashLinkVideo) {
      const videoUrl = this.getYouTubeId(this.data.splashLinkVideo);
      this.videoId = videoUrl;
    }

    setTimeout(() => {
      this.showJumpButton = true;
      this._changeDetectorRef.detectChanges();
    }, 15000);
  }

  getYouTubeId(url: string) {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  }

  onPlayerReady(event: any): void {
    event.target.playVideo();
  }

  onPlayerStateChange(event: any): void {
    if (event.data === 0) {
      this.goAuthentication()
    }
  }

  onError(event: any): void {
    if (event.data == 2 || event.data == 5) {
      this.showJumpButton = true;
      this._changeDetectorRef.detectChanges();
    }
  }

  goAuthentication(): void {
    this.mdDialogRef.close(true);
    window.open(`${this.data.splashDnsCheckout}?creationHash=${this.data.creationHash}`, '_self');
  }

  onResize = (): void => {
    this.videoWidth = Math.min(this.demoYouTubePlayer.nativeElement.clientWidth, 600);
    this.videoHeight = this.mobileDevice ? this.videoWidth * 1.6 : this.videoWidth * 0.6;
    this._changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.mdDialogRef.close(false);
  }

}

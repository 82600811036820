import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { DataSubjectConstants } from 'src/app/constant/data-subject.const';
import { DataSubjectService } from 'src/app/service/data-subject.service';

@Component({
	selector: 'app-success',
	templateUrl: './success.component.html',
	styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {

	successMessage: {
		title: string,
		description: any;
	};
	originColor: string;
	goToMyStore: string;
	linkHowToUsePlatform: string;
	howToUsePlatformText: string;
	learnMoreLink: string;
	videoText: string;
	videoLink: string;
	agencySite: string;

	constructor(
		private dataSubject: DataSubjectService,
		private actRoute: ActivatedRoute,
		private gtag: Gtag) {
		this.successMessage = {
			title: '',
			description: ''
		}
	}

	ngOnInit(): void {
		//this.gtag.event('conversion', {'send_to': 'AW-10880785747/WtxkCLvL0bIDENO6rsQo'});

		addEventListener('popstate', () => {
			window.location.reload();
		});

		this.actRoute.data.subscribe(() => {
			this.dataSubject.register(DataSubjectConstants.REGISTER_SUCCESS_TEXT).subscribe((result: any) => {
				this.agencySite = result.agencySite;
				this.successMessage = result.successMessage;
				this.originColor = result.originColor;
				this.goToMyStore = result.goToMyStoreText;
				this.howToUsePlatformText = result.howToUsePlatformButtonText;
				this.linkHowToUsePlatform = result.linkHowToUsePlatform;
				this.learnMoreLink = result.learnMoreLink;
				this.videoLink = result.videoLink;
				this.videoText = result.videoText;
			});
		});
	}

	gotoMyStore() {
		window.open(this.agencySite, '_blank');
	}

	howToUse() {
		window.open(this.linkHowToUsePlatform, '_blank');
	}
}

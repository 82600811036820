<div class="container">
  <div class="left" [hidden]="hideLeftPanel">
    <app-image></app-image>
  </div>

  <div class="right">
    <router-outlet></router-outlet>
  </div>

</div>

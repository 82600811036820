export class Translate {
  MainImageText: string;
  FormButtonText: string;
  FormTitle: string;
  FormSubtitle: string;
  PageTitle: string;
  LearnMoreButtonText: string;
  SplashTitle: string;
  SplashSubtitle: string;
  SplashTxtButton: string;

  constructor() {
    return {
      MainImageText: '',
      FormButtonText: '',
      FormTitle: '',
      FormSubtitle: '',
      PageTitle: '',
      LearnMoreButtonText: '',
      SplashTitle: '',
      SplashSubtitle: '',
      SplashTxtButton: ''
    }
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private requestCount = 0;

  constructor(private loadService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.requestCount === 0)
      this.loadService.open();

    this.requestCount++;
    return next.handle(req)
      .pipe(
        catchError((err) => {
          this.requestCount--;
          this.loadService.close();
          throw err;
        }))
      .pipe(
        map((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.closeLoader();
          }
          return evt;
        }));
  }

  closeLoader() {
    this.requestCount--;
    if (this.requestCount <= 0)
      return this.loadService.close();

  }

}

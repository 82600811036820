import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() { }
  /**
   * @param date Default date DD/MM/YYYY
   * @returns A new date converted to JSON Date
   */
  static formatDate(date: string) {
    if (!date) return;

    const splittedDate = date.split('/');
    return new Date(
      Number(splittedDate[2]),
      Number(splittedDate[1]) - 1,
      Number(splittedDate[0]),
    );
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from '../compontent/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  instance: MatDialogRef<LoaderComponent>;

  constructor(public dialog: MatDialog) { }

  open() {
    this.instance = this.dialog.open(LoaderComponent,
      {
        disableClose: true,
        panelClass: 'spinner'
      });

      return this.instance;
  }

  close() {
    this.instance.close();
  }
}

<div class="success-container">

    <div class="card">
        <div class="image-container">
            <img src="../../../assets/images/welcome-cuate.svg" alt="">
        </div>

        <div class="message">
            <h1 class="title" [style.color]="originColor">{{successMessage.title}}</h1>
            <div class="description">
                <p>
                    {{successMessage.description.firstLine}}
                </p>
                <p>
                    {{successMessage.description.seccondLine}}
                </p>
                <p style="font-weight: 600" [style.color]="originColor">
                    {{successMessage.description.thirdLine}}
                </p>
            </div>
        </div>

        <div class="card-buttons">
            <button mat-flat-button [style.background-color]="originColor" (click)="gotoMyStore()">{{goToMyStore}}</button>
            <button mat-stroked-button type="button" [style.color]="originColor" [style.border-color]="originColor" (click)="howToUse()" *ngIf="howToUsePlatformText">{{howToUsePlatformText}}</button>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StaticVariableService } from './static-variable.service';

export function InitializeApp(httpClient: HttpClient, staticVar: StaticVariableService): () => Promise<any> | Observable<any> {
  return () => httpClient.get(environment.apiIPUrl)
    .pipe(
      tap((result: any) => {
        staticVar.setVar('ip', result.ip);
      }),
      catchError((err) => {
        return of(err)
      }));
}
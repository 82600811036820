import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidator {

    static ValidateCpf(controle: AbstractControl) {
        const cpf = controle.value;
        if (!!cpf) {
            if (cpf.length !== 11) {
                return { invalidCpf: true };
            }
            if (
                (cpf === '00000000000') ||
                (cpf === '11111111111') ||
                (cpf === '22222222222') ||
                (cpf === '33333333333') ||
                (cpf === '44444444444') ||
                (cpf === '55555555555') ||
                (cpf === '66666666666') ||
                (cpf === '77777777777') ||
                (cpf === '88888888888') ||
                (cpf === '99999999999')) {
                return { invalidCpf: true };
            }
            let numero = 0;
            let caracter = '';
            const numeros = '0123456789';
            let j = 10;
            let somatorio = 0;
            let resto = 0;
            let digito1 = 0;
            let digito2 = 0;
            let cpfAux = '';
            cpfAux = cpf.substring(0, 9);
            for (let i = 0; i < 9; i++) {
                caracter = cpfAux.charAt(i);
                if (numeros.search(caracter) === -1) {
                    return { invalidCpf: true };
                }
                numero = Number(caracter);
                somatorio = somatorio + (numero * j);
                j--;
            }
            resto = somatorio % 11;
            digito1 = 11 - resto;
            if (digito1 > 9) {
                digito1 = 0;
            }
            j = 11;
            somatorio = 0;
            cpfAux = cpfAux + digito1;
            for (let i = 0; i < 10; i++) {
                caracter = cpfAux.charAt(i);
                numero = Number(caracter);
                somatorio = somatorio + (numero * j);
                j--;
            }
            resto = somatorio % 11;
            digito2 = 11 - resto;
            if (digito2 > 9) {
                digito2 = 0;
            }
            cpfAux = cpfAux + digito2;
            if (cpf !== cpfAux) {
                return { invalidCpf: true };
            } else {
                return null;
            }
        }
        return null;
    }

    static ValidatePis(controle: AbstractControl) {
        const pis = controle.value;
        if (!!pis) {
            if (pis.length !== 11) {
                return { invalidPis: true };
            }
            // a) Multiplicar os 11 últimos algarismos pelos pesos conforme abaixo:
            const pesos: number[] = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            const numeros: string = pis.substring(0, pis.length - 1);
            const digito: number = Number(pis.charAt(pis.length - 1));
            let somatorio = 0;

            // b) Somar todos os produtos obtidos no item "a".
            for (let i = 0; i < numeros.length; i++) {
                somatorio = somatorio + (Number(numeros.charAt(i)) * pesos[i]);
            }

            // c) Dividir o somatório do item "b" por 11.
            const resto = somatorio % 11;

            // d) Subtrair de 11 o resto da divisão do item "c".
            // O resultado será o dígito verificador.
            let digitoCalculado = 11 - resto;

            if (digitoCalculado > 9) {
                digitoCalculado = 0;
            }

            if (digito === digitoCalculado) {
                return null;
            } else {
                return { invalidPis: true };
            }

        }
        return null;
    }

    static ValidateCNPJ(controle: AbstractControl) {

        const cnpj = controle.value.replace(/[^\d]+/g, '');

        if (!!cnpj) {
            if (cnpj === '') {
                return { invalidCnpj: true };
            }

            if (cnpj.length !== 14) {
                return { invalidCnpj: true };
            }

            if (cnpj === '00000000000000' ||
                cnpj === '11111111111111' ||
                cnpj === '22222222222222' ||
                cnpj === '33333333333333' ||
                cnpj === '44444444444444' ||
                cnpj === '55555555555555' ||
                cnpj === '66666666666666' ||
                cnpj === '77777777777777' ||
                cnpj === '88888888888888' ||
                cnpj === '99999999999999') {
                return { invalidCnpj: true };
            }

            let tamanho = cnpj.length - 2;
            let numeros = cnpj.substring(0, tamanho);
            const digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado !== digitos.charAt(0)) {
                return { invalidCnpj: true };
            }

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado !== digitos.charAt(1)) {
                return { invalidCnpj: true };
            }
        }

        return null;

    }

    static ValidateNIF(controle: AbstractControl) {
        const nif = controle.value;

        if (!!nif) {
            if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
                !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))) {
                return { invalidNif: true };
            }
            const total = Number(nif[0]) * 9 +
                Number(nif[1]) * 8 +
                Number(nif[2]) * 7 +
                Number(nif[3]) * 6 +
                Number(nif[4]) * 5 +
                Number(nif[5]) * 4 +
                Number(nif[6]) * 3 +
                Number(nif[7]) * 2;

            const modulo11 = total - Math.trunc(total / 11) * 11;
            const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;
            if (Number(nif[8]) !== comparador) {
                return { invalidNif: true };
            }
        }
        return null;

    }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from '../interface/language';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  options: { headers: HttpHeaders }
  apiURL: string;

  constructor(private httpClient: HttpClient, @Inject('institutionId') @Optional() private institutionId: number) {
    this.apiURL = environment.apiURL;
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AgentId': '0',
        'Currencie': '0',
        'InstitutionId': String(this.institutionId)
      })
    }
  }

  post(url: string, params: any, language: Language): Observable<any> {
    const headers = this.options.headers.set('Language', String(language));
    return this.httpClient.post(this.apiURL.concat(url), params, { headers });
  }

  get<T>(url: string, params?: any): Observable<T> {
    const urlParam = params ? `?${params}` : '';
    return this.httpClient.get<T>(this.apiURL.concat(url, urlParam), this.options);
  }
}

<div class="home-container" #backgroundImage>

    <img class="logo" [src]="agencyLogo" alt="" *ngIf="agencyLogo">

    <div class="flags" [ngClass]="{'embedded-hidden':embedded}">
        <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedLanguage" panelClass="language-options" (selectionChange)="languageChanged($event)">
                <mat-select-trigger>
                    <div class="flag-container">
                        <img [src]="selectedLanguage.flag">
                    </div>
                </mat-select-trigger>
                <mat-option [value]="lang" *ngFor="let lang of availableLanguages">
                    <span>{{lang.name}}</span>
                    <img [src]="lang.flag">
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="body">
        <div class="form" [ngClass]="{'embedded-form':embedded}">

            <div class="header" [ngClass]="{'embedded-hidden':embedded}">
                <h2 [innerHTML]="languageConfig.FormTitle | sanitizer"></h2>
                <h3 [innerHTML]="languageConfig.FormSubtitle |sanitizer"></h3>
            </div>

            <form [formGroup]="partnerForm" (ngSubmit)="becomePartner()" #partnerFormEl="ngForm">

                <div class="inputs">

                    <mat-form-field appearance="outline" class="agent-name">
                        <mat-label>{{langTextJson.cardForm?.label.fullName}}</mat-label>
                        <input (blur)="checkErrosNotification()" matInput formControlName="agentName">
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.agentName.errors?.required && (partnerForm.controls.agentName.dirty || partnerForm.controls.agentName.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="email">
                        <mat-label>{{langTextJson.cardForm?.label.email}}</mat-label>
                        <input (blur)="checkErrosNotification()" matInput formControlName="email">
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.email.errors?.pattern && (partnerForm.controls.email.dirty || partnerForm.controls.email.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.emailInvalid}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.email.errors?.required && (partnerForm.controls.email.dirty || partnerForm.controls.email.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.email.errors && partnerForm.controls.email.errors?.emailAlreadyInUse">
                          <span class="material-icons mat-error-icon">cancel</span>
                          {{langTextJson.failedMessages?.email}}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="country" [ngClass]="{'embedded-hidden':embedded}">
                        <mat-label>{{langTextJson.cardForm?.label.country}}</mat-label>
                        <mat-select formControlName="country" (selectionChange)="countryChanged($event)">
                            <mat-option *ngFor="let country of countryList" [value]="country">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.country.errors?.required && (partnerForm.controls.country.dirty || partnerForm.controls.country.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="agency-name">
                        <mat-label>{{langTextJson.cardForm?.label.agencyName}}</mat-label>
                        <input (blur)="checkErrosNotification()" matInput formControlName="agencyName" autocomplete="off" [placeholder]="langTextJson.cardForm?.label.agencyPlaceHolder" maxlength="30">
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.agencyName.errors?.required && (partnerForm.controls.agencyName.dirty || partnerForm.controls.agencyName.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="birth-date">
                        <mat-label>{{langTextJson.cardForm?.label.birthDate}}</mat-label>
                        <input (blur)="checkErrosNotification()" matInput formControlName="birthDate" inputmode="numeric" maxlength="10" minlength="10" type="numeric" [mask]="dateTimeFormat" [placeholder]="dateTimePlaceHolder" required [dropSpecialCharacters]="false">
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.birthDate.errors?.pattern && (partnerForm.controls.birthDate.dirty || partnerForm.controls.birthDate.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.dateInvalid}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.birthDate.errors?.required && (partnerForm.controls.birthDate.dirty || partnerForm.controls.birthDate.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.birthDate.errors?.minimumAge && (partnerForm.controls.birthDate.dirty || partnerForm.controls.birthDate.touched) && !partnerForm.controls.birthDate.errors?.pattern">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{minimumAgeError()}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="doc-number">
                        <mat-label *ngIf="documentPlaceHolder">{{documentPlaceHolder}}</mat-label>
                        <mat-label *ngIf="!documentPlaceHolder">{{langTextJson.cardForm?.label.documentNumber}}</mat-label>
                        <input (blur)="checkErrosNotification()" matInput formControlName="documentNumber" [mask]="documentMask" [dropSpecialCharacters]="true" [placeholder]="documentPlaceHolder" required>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.documentNumber.errors?.invalidNif && (partnerForm.controls.documentNumber.dirty || partnerForm.controls.documentNumber.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.nifInvalid}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.documentNumber.errors?.invalidCpf && (partnerForm.controls.documentNumber.dirty || partnerForm.controls.documentNumber.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.cpfInvalid}}
                        </mat-error>
                        <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.documentNumber.errors?.required && (partnerForm.controls.documentNumber.dirty || partnerForm.controls.documentNumber.touched)">
                            <span class="material-icons mat-error-icon">cancel</span>
                            {{langTextJson.failedMessages?.required}}
                        </mat-error>
                    </mat-form-field>

                      <mat-form-field appearance="outline" class="state">
                          <mat-label>{{langTextJson.cardForm?.label.state}}</mat-label>
                          <mat-select formControlName="state" name="state" (selectionChange)="stateChanged($event)" class="state" [compareWith]="compareCategoryObjects">
                            <mat-option *ngFor="let state of statesList" [value]="state">
                                {{state.name}}
                            </mat-option>
                          </mat-select>
                          <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.state.errors?.required && (partnerForm.controls.state.dirty || partnerForm.controls.state.touched)">
                              <span class="material-icons mat-error-icon">cancel</span>
                              {{langTextJson.failedMessages?.required}}
                          </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="city">
                          <mat-label>{{langTextJson.cardForm?.label.city}}</mat-label>
                          <mat-select formControlName="city" name="city" (selectionChange)="cityChanged($event)" class="city" [compareWith]="compareCategoryObjects">
                              <mat-option *ngFor="let city of cityList" [value]="city">
                                  {{city.name}}
                              </mat-option>
                          </mat-select>
                          <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.city.errors?.required && (partnerForm.controls.city.dirty || partnerForm.controls.city.touched)">
                              <span class="material-icons mat-error-icon">cancel</span>
                              {{langTextJson.failedMessages?.required}}
                          </mat-error>
                      </mat-form-field>

                    <div class="phone">
                        <mat-form-field appearance="outline" class="phone-number">
                            <mat-label>{{langTextJson.cardForm?.label.phoneNumber}}</mat-label>
                            <input (blur)="checkErrosNotification()" matInput formControlName="phoneNumber" type="text" inputmode="numeric" maxlength="15" mask="{{phoneNumberMask}}" [dropSpecialCharacters]="true" [validation]="true" required>
                            <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.phoneNumber.errors?.required && (partnerForm.controls.phoneNumber.dirty || partnerForm.controls.phoneNumber.touched)">
                                <span class="material-icons mat-error-icon">cancel</span>
                                {{langTextJson.failedMessages?.required}}
                            </mat-error>
                            <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.phoneNumber.errors?.mask && (partnerForm.controls.phoneNumber.dirty || partnerForm.controls.phoneNumber.touched)">
                                <span class="material-icons mat-error-icon">cancel</span>
                                {{langTextJson.failedMessages?.phoneNumber}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-action">
                    <div class="terms" *ngIf="termsDescription && partnerForm.controls.country.value">
                        <mat-checkbox class="checkbox" formControlName="termsOfUse" (change)="registerAcceptTime($event)"></mat-checkbox>
                        <div class="description-terms">
                            <div [innerHTML]="termsDescription | sanitizer"> </div>
                            <mat-error class="mat-error-custom" *ngIf="partnerForm.controls.termsOfUse.errors?.required">
                                <span class="material-icons mat-error-icon">cancel</span>
                                {{langTextJson.cardForm?.politics.termsErrorMessage}}
                            </mat-error>
                        </div>
                    </div>

                    <button mat-flat-button type="button" [style.background-color]="'#ccc'" [disabled]="!hasCaptchaToken" *ngIf="!hasCaptchaToken">{{languageConfig.FormButtonText}}</button>
                    <button mat-flat-button type="submit" class="g-recaptcha" [style.background-color]="configDefaultByAgency.defaultConfig?.FormButtonColor" [disabled]="!hasCaptchaToken" *ngIf="hasCaptchaToken">{{languageConfig.FormButtonText}}</button>
                    <button mat-stroked-button type="button" [style.color]="configDefaultByAgency.defaultConfig?.FormButtonColor" [style.border-color]="configDefaultByAgency.defaultConfig?.FormButtonColor" *ngIf="configDefaultByAgency.defaultConfig?.LinkToLearnMore" (click)="learnMore()">{{languageConfig.LearnMoreButtonText}}</button>
                </div>

            </form>
        </div>
    </div>
</div>


<ng-template #templateErrorInfo>
    <div class="aling-row">
        <div class="aling-icon">
            <i class="material-icons alert-error-span">cancel</i>
        </div>
        <span class="alert-error-span">{{ totalErrosMessage }}</span>
    </div>
</ng-template>

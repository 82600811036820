import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSubjectService {

  event: { [key: string]: BehaviorSubject<any> };

  constructor() {
    this.event = {};
  }

  register(arg: string): Observable<any> {
    const eventName = arg;

    if (!this.event[eventName]) {
      this.event = {
        ...this.event,
        [eventName]: new BehaviorSubject('')
      };
    }
    return this.event[eventName].asObservable();
  }

  sendValue(arg: string, value?: any) {
    const eventName = arg;    

    if (!this.event[eventName])
      this.event = {
        ...this.event,
        [eventName]: new BehaviorSubject('')
      };

    this.event[eventName].next(value);
  }
}

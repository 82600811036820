export class DefaultConfiguration {
  Logo: string;
  MainImage: string;
  FormButtonColor: string;
  Favicon: string;
  MainImagePosition: string;
  MainTextPosition: string;
  DocumentIsOptional: string;
  SuccessVideoLink: string;
  LinkToLearnMore: string;
  LinkHowToUsePlatform: string;
  SplashLogo: string;
  SplashLinkVideo: string;
  SplashDnsCheckout: string;
  SplashBackgroundColor: string;

  constructor() {
    return {
      Logo: '',
      MainImage: '',
      FormButtonColor: '',
      Favicon: '',
      MainImagePosition: '',
      MainTextPosition: '',
      DocumentIsOptional: '',
      SuccessVideoLink: '',
      LinkToLearnMore: '',
      LinkHowToUsePlatform: '',
      SplashLogo: '',
      SplashLinkVideo: '',
      SplashDnsCheckout: '',
      SplashBackgroundColor: ''
    }
  }
}

export class DataSubjectConstants {
    static readonly BACKGROUND: string = 'BackgroundImage';
    static readonly LOGO: string = 'LogoImage';
    static readonly IMAGE_TEXT: string = 'MainImageText';
    static readonly REGISTER_SUCCESS_TEXT: string = 'SuccessText';
    static readonly BACKGROUND_IMAGE_POSITION: string = 'BackgroundImagePosition';
    static readonly BACKGROUND_TEXT_POSITION: string = 'BackgroundTextPosition';
    static readonly VIDEO_LINK: string = 'VideoLink';
    static readonly SUCCESS_BACKGROUND: string = 'SuccessBackground';
    static readonly SUCCESS_SPLASH_SCREEN: string = 'SuccessSplashScreen';
}
